<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { buttonVariants } from "./button-variants";

type Props = {
  icon: string;
  label: string;
  type?: "button" | "submit";
  variant?: "ghost" | "filled" | "filledTonal" | "outlined";
  disabled?: boolean;
  isLoading?: boolean;
  class?: HTMLAttributes["class"];
};

const props = withDefaults(defineProps<Props>(), {
  icon: "ic:outline-settings",
  type: "button",
  variant: "ghost",
  disabled: false,
  isLoading: false,
  class: "",
});

const delegatedProps = computed(() => {
  const { icon, label, variant, ...delegated } = props;
  return delegated;
});

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <div>
    <UITooltipProvider>
      <UITooltip>
        <UITooltipTrigger as-child>
          <button
            v-bind="{ ...delegatedProps, ...$attrs }"
            :class="
              cn(
                buttonVariants({
                  variant: props.variant,
                  disabled: props.disabled,
                }),
                'size-max shrink-0 p-3',
                props.class,
              )
            "
          >
            <Icon
              :name="props.isLoading ? 'svg-spinners:ring-resize' : props.icon"
              class="size-6 text-inherit"
            />
          </button>
          <span class="sr-only">{{ props.label }}</span>
        </UITooltipTrigger>

        <UITooltipContent>
          <p>{{ props.label }}</p>
        </UITooltipContent>
      </UITooltip>
    </UITooltipProvider>
  </div>
</template>
